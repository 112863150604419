import React from 'react'
import { Element } from 'react-scroll'
import { graphql } from 'gatsby'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/landing1/Hero'
import { SliceZone } from '../components/SliceZone'
import SEO from '../components/seo'

const IndexPage = ({data}) => {
  if (!data) return null
  const doc = data.prismicHomepage

  return (
    <>
      <PageWrapper darkTheme={true}>
        <SEO title={doc.data.title.text} description={doc.data.meta_description.text}/>
        <Hero banner_images={doc.data.banner_images} header_cta={doc.data.header_cta}/>

        <SliceZone sliceZone={doc.data.body} />
        
      </PageWrapper>
    </>
  )
}
export default IndexPage

export const query = graphql`
  query HomePageQuery {
    prismicHomepage{
      data {
        banner_images {
          image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
        meta_description {
          text
        }
        title {
          text
        }
  
        header_cta {
          text
        }
        meta_description {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyRentalProperties
          ...HomepageDataBodyOurServices
          ...HomepageDataBodyRichTextBasic
          ...HomepageDataBodyTestimonials
          ...HomepageDataBodyRichTextWithCta
          ...HomepageDataBodyRichTextWithCtaBannerImage
        }
      }
    }
  }
`


/*
        <RichText
          copy="Ready to turn your property into an investment with good quality tenants and high rental returns?"
          cta="Contact Us Now!"
          lines={true}
          textColor="black"
        />
        <Rentals
          title="Our Rental Properties"
          textColor="#707070"
          bg="#F1F3F4"
        />
        <RichText
          copy="Enquire now to receive your free desktop rental appraisal"
          subCopy="We have a few unique ways to do it."
          cta="Sign up now!"
          textColor="#454545"
          opacity="#E7E7E7"
          bgImg={waterBg}
          arrow={true}
          bold={true}
        />
        <OurServices />
        <About inset={true} />
        <Testimonials />
        <Blogs inset={true} recent={true} />

*/